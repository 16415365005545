import AppSelect from 'app/components/Select';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

interface CustomDropdownFilterProps {
    values: string[];
    api: any;
    colDef: any;
    column: any;
    columnApi: any;
    context: any;
    valueGetter: (params: any) => string;
    filterChangedCallback: () => void;
}

const CustomDropdownFilter = forwardRef<any, CustomDropdownFilterProps>(
    ({ values, api, colDef, column, columnApi, context, valueGetter, filterChangedCallback }, ref) => {
        const [selectedValues, setSelectedValues] = useState<string[]>(['reset']);

        const onDropdownChange = (values: any) => {
            const simplifiedValues = values ? values.map((item: any) => item.value) : [];

            setSelectedValues(simplifiedValues.length ? simplifiedValues : ['reset']);
        };

        const onReset = () => {
            setSelectedValues(['reset']);
        };

        useImperativeHandle(ref, () => ({
            doesFilterPass(params: any) {
                const { node } = params;
                const currentValue = valueGetter({
                    api,
                    colDef,
                    column,
                    columnApi,
                    context,
                    data: node.data,
                    getValue: (field: any) => node.data[field],
                    node
                });

                return selectedValues.includes(currentValue);
            },

            isFilterActive() {
                return selectedValues.length > 0 && selectedValues[0] !== 'reset';
            },

            getModel() {
                if (!this.isFilterActive()) {
                    return null;
                }

                return { value: selectedValues };
            },

            setModel(model: any) {
                setSelectedValues(model?.value || ['reset']);
            }
        }));

        useEffect(() => {
            filterChangedCallback();
        }, [selectedValues, filterChangedCallback]);

        const customStyles = {
            control: (provided: any) => ({
                ...provided,
                background: '#fff',
                borderColor: '#9e9e9e',
                minHeight: '25px',
                height: '25px'
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                height: '25px',
                padding: '0 6px'
            }),
            input: (provided: any) => ({
                ...provided,
                margin: '0px'
            }),
            indicatorSeparator: () => ({
                display: 'none'
            }),
            indicatorsContainer: (provided: any) => ({
                ...provided,
                height: '25px'
            })
        };

        return (
            <form onSubmit={(e) => e.preventDefault()} className='ag-filter-wrapper ag-focus-managed'>
                <div className='ag-filter-body-wrapper ag-simple-filter-body-wrapper'>
                    <div className='ag-picker-field ag-labeled ag-label-align-left ag-select ag-filter-select'>
                        <AppSelect
                            placeholder='Select options'
                            isMulti={true}
                            styles={customStyles}
                            value={selectedValues[0] === 'reset'
                                ? null
                                : selectedValues.map((value) => ({ label: value, value }))}
                            onChange={onDropdownChange}
                            className='w-100'
                            menuPosition='fixed'
                            options={values.map((item) => ({ value: item, label: item }))} />
                    </div>
                </div>
                <div className='ag-filter-apply-panel'>
                    <button
                        type='button'
                        className='ag-button ag-standard-button ag-filter-apply-panel-button ms-0'
                        onClick={onReset}>
                        Reset
                    </button>
                </div>
            </form>
        );
    }
);

export default CustomDropdownFilter;

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

interface TimeCellEditorProps {
    value: string;
    stopEditing: () => void;
}

const TimeCellEditor = forwardRef((props: TimeCellEditorProps, ref) => {
    const [value, setValue] = useState(props.value);
    const inputRef = useRef<HTMLInputElement>(null); // Reference to the input element

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    // Expose methods to AG Grid via `useImperativeHandle`
    useImperativeHandle(ref, () => ({
        getValue() {
            return value;
        }
    }));

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        setValue(inputValue);
    };

    return (
        <input
            ref={inputRef} // Attach the ref to the input element
            className='time-input'
            type='time'
            step='1'
            value={value}
            onChange={handleInputChange}
            onBlur={props.stopEditing}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    props.stopEditing();
                }
            }} />
    );
});

export default TimeCellEditor;

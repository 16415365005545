import {
    FC,
    useState,
    useEffect,
    createContext,
    useContext,
    useRef,
    Dispatch,
    SetStateAction
} from 'react';
import ReactGA from 'react-ga4';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { AuthModel, UserModel } from './_models';
import * as authHelper from './AuthHelpers';
import { WithChildren } from '../../../../_metronic/helpers';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { FILTER_VERSION_KEY, NAV_ORIENTATION_KEY, NAV_VERSION_KEY } from 'app/utils/constants';
import useConfigStore from 'app/store/userConfig';

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => undefined,
    currentUser: undefined,
    setCurrentUser: () => undefined,
    logout: () => undefined
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
    return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
    const { logout: auth0logout } = useAuth0();

    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth);

        if (auth) {
            authHelper.setAuth(auth);
        } else {
            authHelper.removeAuth();
        }
    };

    const logout = () => {
        saveAuth(undefined);
        setCurrentUser(undefined);
        auth0logout({ returnTo: window.location.origin });
    };

    return (
        <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
    const { saveAuth, setCurrentUser } = useAuth();
    const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { setInitialConfigs } = useConfigStore();

    const didRequest = useRef(false);
    const [showSplashScreen, setShowSplashScreen] = useState(true);

    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestUser = async () => {
            if (!isLoading) {
                if (!didRequest.current && isAuthenticated) {
                    const token = await getAccessTokenSilently();

                    const transformedResponse = {} as UserModel;

                    for (const key in user) {
                        if (key.includes('/app_metadata')) {
                            transformedResponse.app_metadata = user[key];
                        } else {
                            transformedResponse[key as keyof UserModel] = user[key];
                        }
                    }

                    saveAuth({ token });
                    setCurrentUser(transformedResponse);

                    ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID!, { gaOptions: { userId: user?.sub } });

                    Sentry.setUser({
                        id: user?.sub,
                        username: user?.name,
                        email: user?.email
                    });

                    didRequest.current = true;

                    const navbarOrientation = localStorage.getItem(`${NAV_ORIENTATION_KEY}-${user?.sub}`) || 'horizontal';
                    const navbarVersion = localStorage.getItem(`${NAV_VERSION_KEY}-${user?.sub}`) || 'version1';
                    const filterVersion = localStorage.getItem(`${FILTER_VERSION_KEY}-${user?.sub}`) || 'popOut';

                    setInitialConfigs({ navbarVersion, filterVersion, navbarOrientation, user: user?.sub || '' });
                } else if (!isAuthenticated) {
                    saveAuth(undefined);
                    setCurrentUser(undefined);
                }

                setShowSplashScreen(false);
            }
        };

        requestUser();
    }, [isLoading, isAuthenticated]);

    return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };

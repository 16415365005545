import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { RaveType } from './types';

const base = 'sign';

// Production Planning
const GET_RAVE_DATA = 'getRaveData';

export function useGetRaveData({ ...options }: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<RaveType[]>([GET_RAVE_DATA], `/${base}/spreadsheet/rave`, { ...options, method: 'GET' });
}

export async function updateRaveData(data: RaveType[]) {
    return await makeApiRequest<any>(`/${base}/spreadsheet/rave/update`, 'POST', data, 'application/json');
}

export async function deleteRaveRows(ids: number[]) {
    return await makeApiRequest<boolean>(`/${base}/spreadsheet/rave/delete`, 'DELETE', ids);
}

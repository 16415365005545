import { useEffect, useMemo, useState } from 'react';
import AppButton from 'app/components/Button/AppButton';
import useConfigStore from 'app/store/userConfig';
import useToast from 'app/hooks/useToast';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { updateRaveData, useGetRaveData } from 'app/api/sign/sign';
import { RaveType } from 'app/api/sign/types';
import { ORIENTATION } from 'app/utils/constants';
import { findChangedObjects, updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';
import useSpreadsheet from '../../hooks/useSpreadsheet';
import useDeleteRows from './hooks/useDeleteRows';
import useCreateRaveColumns from './hooks/useCreateRaveColumns';

import '../../spreadsheet-grid.scss';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetRave = ({ report, className }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [freshData, setFreshData] = useState<RaveType[]>([]);

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();
    const { setNotification } = useToast();

    const { data: sheetDataResponse, isLoading: isSpreadsheetDataLoading, refetch } = useGetRaveData(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            onSuccess: (data) => {
                setFreshData(data.data.data);
            }

        });

    const { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: RaveType[] = useMemo(() => sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [], [sheetDataResponse]);

    const createdColumns = useCreateRaveColumns(sheetData[0] || {}, report);
    const { handleDeleteModal } = useDeleteRows(gridApi);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport sheetData={sheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const handleSaveSpreadsheet = async () => {
        setIsSaving(true);

        const updatedValues: RaveType[] = [];

        if (gridApi) {
            gridApi.forEachNode((node) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        try {
            const changedObjects = findChangedObjects<RaveType>(freshData, updatedValues);

            await updateRaveData(changedObjects);
            setNotification('Sheet saved', 'success');
            refetch();
            setIsSheetUpdated(false);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setNotification(error.response.data.message, 'error');
            } else {
                setNotification('Failed to update spreadsheet', 'error');
            }
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            </div>
            <div className={`spreadsheet card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3' />
                    <div className='d-flex justify-content-end gap-3' >
                        <AppButton size='sm'
                            onClick={() => handleDeleteModal(gridApi ? gridApi.getSelectedRows() : [])}>Delete Row</AppButton>
                        <AppButton disabled={isSaving || !isSheetUpdated} isLoading={isSaving} size='sm'
                            onClick={handleSaveSpreadsheet}>
                            Save
                        </AppButton>
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        sheetData={sheetData}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetRave;

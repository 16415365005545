import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { MSSReferenceData, MSSReferenceDataMap } from './types';

const GET_REFERENCE_DATA = 'getReferenceData';

const base = 'MSSReference';

export function useGetMSSReferenceData<K extends keyof MSSReferenceDataMap>(referenceTableId: K, options: UseApiRequestOptions<any> = {}) {
    return useReactQueryRequest<MSSReferenceDataMap[K][]>([`${GET_REFERENCE_DATA}-${referenceTableId}`], `${base}/spreadsheet/${referenceTableId}`, {
        ...options,
        method: 'GET'
    });
}

export async function updateMSSReferenceData<T extends MSSReferenceData>(data: T[], referenceTableID: string) {
    return await makeApiRequest<boolean>(`${base}/spreadsheet/update/${referenceTableID}`, 'POST', data);
}

export async function deleteMSSReferenceData(ids: number[], referenceTableId: string) {
    return await makeApiRequest<boolean>(`${base}/spreadsheet/delete/${referenceTableId}`, 'DELETE', ids);
}

export async function duplicateMSSPresentationTimeData<MSSPresentationTimeDuplicate>(data: MSSPresentationTimeDuplicate) {
    return await makeApiRequest<boolean>(`${base}/spreadsheet/presentationTime/duplicate`, 'POST', data);
}

import useModalStore from 'app/store/modal';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Typography from 'app/components/Typography';
import { duplicateMSSPresentationTimeData } from 'app/api/mss/mss';
import useToast from 'app/hooks/useToast';

type Props = {
    selectedRowsId: number[];
    refetch: () => void;
}

interface FormValues {
    AIRPORT: string;
    AIRLINE: string;
    CATEGORY: string;
}

const PresentationTimeForm = ({ selectedRowsId, refetch }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm<FormValues>();

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const payload = {
            AIRPORT: data.AIRPORT || null,
            AIRLINE: data.AIRLINE || null,
            CATEGORY: data.CATEGORY || null,
            ID: selectedRowsId
        };

        try {
            await duplicateMSSPresentationTimeData(payload);
            refetch();
            setNotification('Rows duplicated successfully', 'success');
            clearModalContent();
        } catch (error) {
            setNotification('Error duplicating rows', 'error');
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Typography className='mb-3'>Set new values to the duplicated rows or leave the field blank to duplicate without any changes.</Typography>
            <Row className='mb-3'>
                <Col>
                    <Form.Group controlId='AIRPORT'>
                        <Form.Label>Airport</Form.Label>
                        <Form.Control
                            type='text'
                            {...register('AIRPORT')}
                            isInvalid={!!errors.AIRPORT} />
                        <Form.Control.Feedback type='invalid'>
                            {errors.AIRPORT?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row className='mb-3'>
                <Col>
                    <Form.Group controlId='AIRLINE'>
                        <Form.Label>Airline</Form.Label>
                        <Form.Control
                            type='text'
                            {...register('AIRLINE')}
                            isInvalid={!!errors.AIRLINE} />
                        <Form.Control.Feedback type='invalid'>
                            {errors.AIRLINE?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row className='mb-3'>
                <Col>
                    <Form.Group controlId='CATEGORY'>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            type='text'
                            {...register('CATEGORY')}
                            isInvalid={!!errors.CATEGORY} />
                        <Form.Control.Feedback type='invalid'>
                            {errors.CATEGORY?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <div className='d-flex justify-content-end gap-3'>
                <Button onClick={clearModalContent} variant='secondary' size='sm'>Cancel</Button>
                <Button type='submit' disabled={isSubmitting} size='sm'>Submit</Button>
            </div>
        </Form>
    );
};

const useDuplicatePresentationTimeRows = () => {
    const { setModalContent } = useModalStore();

    const handleDuplicatePresentationTimeModal = (selectedRows: { ID: number }[], refetch: () => void) => {
        const selectedRowsId = selectedRows.map((data) => data.ID);

        setModalContent({
            content: selectedRows.length > 0 ? <PresentationTimeForm selectedRowsId={selectedRowsId} refetch={refetch} /> : <Typography className='mb-5'>Please select rows to duplicate.</Typography>,
            title: `Duplicate Row${selectedRows.length > 1 ? 's' : ''}`
        });
    };

    return { handleDuplicatePresentationTimeModal };
};

export default useDuplicatePresentationTimeRows;
